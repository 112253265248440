import { useMemo } from 'react';
import { QueryHookOptions } from '@kv/apollo-client';


import {
  GetVanityUrls,
  GetVanityUrl,
  CreateVanityUrl,
  UpdateVanityUrl,
  DeleteVanityUrls,
} from '~/graphql';

import type {
  VanityUrlsQueryResultData,
  VanityUrlQueryResultData,
  CreateUpdateVanityUrlInput,
  CreateUpdateVanityUrlMutationResultData,
  DeleteVanityUrlsMutationResultData,
} from '~/types';

import {
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeCreateEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeDeleteEntitiesMutationHook,
  getIlikeFilterValue,
  getInFilterValue,
} from './utils';

export const useGetVanityUrlsQuery =
  makeGetEntitiesQueryHook<VanityUrlsQueryResultData>(GetVanityUrls);

export const useGetVanityUrlByIdQuery =
  makeGetEntityByIdQueryHook<VanityUrlQueryResultData>(GetVanityUrl);

type SearchVanityUrlQueryVars = {
  value?: string;
  entityId?: string;
  redirectTo?: string;
  ids?: string[];
};

export const useSearchVanityUrlsQuery = (
  { value, entityId, redirectTo, ids }: SearchVanityUrlQueryVars,
  options?: QueryHookOptions,
) => {
  const variables = useMemo(
    () => ({
      filters: {
        or: [
          { valueIlike: getIlikeFilterValue(value) },
          { entityIdIlike: getIlikeFilterValue(entityId) },
          { redirectToIlike: getIlikeFilterValue(redirectTo) },
          { idIn: getInFilterValue(ids) },
        ],
      },
    }),
    [value, ids, entityId, redirectTo],
  );

  return useGetVanityUrlsQuery(variables, options);
};

export const useCreateVanityUrlMutation = makeCreateEntityMutationHook<
  CreateUpdateVanityUrlMutationResultData,
  CreateUpdateVanityUrlInput
>(CreateVanityUrl);

export const useUpdateVanityUrlMutation = makeUpdateEntityMutationHook<
  CreateUpdateVanityUrlMutationResultData,
  CreateUpdateVanityUrlInput
>(UpdateVanityUrl);

export const useDeleteVanityUrlMutation =
  makeDeleteEntitiesMutationHook<DeleteVanityUrlsMutationResultData>(DeleteVanityUrls);
