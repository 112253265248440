import { FC, useEffect } from 'react';
import { Redirect, RedirectProps } from 'react-router-dom';

import { AppearanceTypes, useToasts } from 'react-toast-notifications';

const defaultTimeout = 15_000;

interface RedirectWithNotificationProps extends RedirectProps {
  notification?: string;
  appearance?: AppearanceTypes
  notificationTimeout?: number;
}

const RedirectWithNotification: FC<RedirectWithNotificationProps> = ({
  notification,
  appearance,
  notificationTimeout,
  ...redirectProps
}) => {

  const { addToast } = useToasts();
  const autoDismissTimeout = notificationTimeout || defaultTimeout

  useEffect(() => {
    if (notification) addToast(notification, { appearance, autoDismissTimeout });
  });

  return <Redirect {...redirectProps} />;
};

export default RedirectWithNotification;
