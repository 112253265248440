import { useQuery } from '@kv/apollo-client';

import {
  GetAuthTokens,
  UserLogin,
  UserLogout,
  RefreshAccessToken,
} from '~/graphql';

import type {
  AuthTokensQueryResult,
  UserLoginInput,
  UserLoginMutationResult,
  UserLogoutMutationResult,
  RefreshAccessTokenMutationResult,
} from '~/types';

import { makeStandardMutationHook } from './utils/mutations';

export const useGetAuthTokensQuery = () =>
  useQuery<AuthTokensQueryResult>(GetAuthTokens, { fetchPolicy: 'no-cache' });

export const useUserLoginMutation = makeStandardMutationHook<
  UserLoginMutationResult,
  UserLoginInput
>(UserLogin);

export const useUserLogoutMutation =
  makeStandardMutationHook<UserLogoutMutationResult>(UserLogout);

export const useRefreshAccessTokenMutation =
  makeStandardMutationHook<RefreshAccessTokenMutationResult>(
    RefreshAccessToken,
  );
