import { isEmpty } from "lodash";

export const toServerUrl = (filepath?: string) => {
  if (!filepath) return '';
  if (filepath.startsWith('http')) return filepath;

  return `${window.location.origin}${filepath}`;
};

export const getFilenameFromPath = (filepath?: string) => {
  if (!filepath) return '';
  return filepath.replace(/^.*[/\\]/, '')
}

export const isValidRedirectPath = (path:string | undefined | null) => {

  if (!path) return false;

  return (
    !isEmpty(path) &&
    path !== "/" &&
    !path.toLowerCase().includes("login")
  )
};
