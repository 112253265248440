import { curry, pathOr, pick } from 'ramda';
import { uniqueId } from 'lodash';

import {
  MatterportSDK,
  MatterPortLightComponentName,
  ThreeLightOptions,
  ThreeObj3D,
  ThreeXyzPropName,
  ThreeNode,
  ThreeXyzMap,
  MatterportAssetItem,
  MatterportModelOptions,
} from '~/types';

import { createSceneNode } from './nodes';
import { getMatterportAssetDataFromModel, getThreeScene } from './scenes';
import { toRadians } from './units';

// A "model" is any THREE.js object
// Can be the scene itself, a light, a camera, a 3D model, a mesh, a material, etc

export const discardModel = (model: ThreeObj3D, SDK: MatterportSDK) => {
  getThreeScene(SDK).then(scene => {
    scene?.remove(model);
  });
};

export const duplicateModel = (model: ThreeObj3D, SDK: MatterportSDK) => {
  const matterportAssetItem: MatterportAssetItem =
    getMatterportAssetDataFromModel(model);
  matterportAssetItem.modelId = uniqueId(matterportAssetItem.modelId);
  return add3DModelFromAsset(SDK, matterportAssetItem);
};

export const addLight = async (
  sdk: MatterportSDK,
  lightType: MatterPortLightComponentName,
  lightOptions?: ThreeLightOptions,
) => {
  const node = await createSceneNode(sdk);

  if (!node || !sdk) return;

  node.addComponent(lightType, lightOptions);
  node.start();

  return node;
};

export const handleTransparencyEffect = (
  obj3D: ThreeObj3D,
  active: boolean,
) => {
  // loop through all children
  // if the child is a material, set transparency mode
  const enableTransparencyForChildren = (children: ThreeObj3D[]) => {
    children.forEach(child => {
      if (child?.material) {
        child.material.transparent = active;
      }

      if (child?.children?.length) {
        enableTransparencyForChildren(child.children);
      }
    });
  };

  enableTransparencyForChildren(obj3D.children);
};

export const handleWireframeEffect = (obj3D: ThreeObj3D, active: boolean) => {
  // loop through all children
  // if the child is a material, set wireframe mode
  const enableWireframeForChildren = (children: ThreeObj3D[]) => {
    children.forEach(child => {
      if (child?.material) {
        child.material.wireframe = active;
      }
      if (child?.children?.length) {
        enableWireframeForChildren(child.children);
      }
    });
  };

  enableWireframeForChildren(obj3D.children);
};

export const rotationXyzToRadians = (rotation: ThreeXyzMap): ThreeXyzMap => ({
  x: toRadians(rotation.x),
  y: toRadians(rotation.y),
  z: toRadians(rotation.z),
});

export const getXyzMapFromNumber = (value: number): ThreeXyzMap => ({
  x: value,
  y: value,
  z: value,
});

export const pickXyzMapProps = pick(['x', 'y', 'z']);

const DEFAULT_LOCAL_SCALE = 0.05;

export const getDefaultModelOptions = (
  url: string,
): MatterportModelOptions => ({
  url,
  visible: true,
  localScale: getXyzMapFromNumber(DEFAULT_LOCAL_SCALE),
});

export const add3DModelFromAsset = async (
  sdk: MatterportSDK,
  matterportAssetItem: MatterportAssetItem,
  options = getDefaultModelOptions(matterportAssetItem.modelUrl),
  onModelLoadCallback?: (node: ThreeNode) => void,
) => {
  const node = await createSceneNode(sdk);

  if (!node || !sdk) {
    return;
  }

  const component = node.addComponent('mp.fbxLoader', options);

  component.inputs.onLoaded = (obj3D: ThreeObj3D | null) => {
    onModelLoadCallback?.(node);
    if (!obj3D) {
      return;
    }

    handleTransparencyEffect(obj3D, true);

    const modelPosition =
      matterportAssetItem?.position || getXyzMapFromNumber(0);

    const modelRotation = matterportAssetItem?.rotation
      ? rotationXyzToRadians(matterportAssetItem?.rotation)
      : getXyzMapFromNumber(0);

    const modelScale = matterportAssetItem?.scale || 0;

    setModelPosition(modelPosition, obj3D);
    setModelRotation(modelRotation, obj3D);
    setModelScale(modelScale, obj3D);

    const modelLight = {
      enabled: true,
      color: { r: 1, g: 1, b: 1 },
      intensity: 1,
      position: { ...modelPosition, y: modelPosition.y + 5 },
      distance: 0,
      decay: 0,
      debug: false,
    };

    addLight(sdk, 'mp.pointLight', modelLight);
  };

  const { modelId, modelUrl, name } = matterportAssetItem;
  node.obj3D.userData = { modelId, modelUrl, name };
  node.start();

  return node;
};

export const getModelXyzByProp = curry(
  (
    propName: 'position' | 'rotation',
    xyzPropName: ThreeXyzPropName,
    model: ThreeObj3D,
  ): number => pathOr(0, [propName, xyzPropName], model),
);

export const setModelXyzByProp = curry(
  (
    propName: 'position' | 'rotation',
    xyzPropName: ThreeXyzPropName,
    value: number,
    model: ThreeObj3D,
  ) => {
    model[propName][xyzPropName] = value;
    return model;
  },
);

export const incrementModelXyzByProp = curry(
  (
    propName: 'position' | 'rotation',
    xyzPropName: ThreeXyzPropName,
    value: number,
    model: ThreeObj3D,
  ) => {
    const currentValue = getModelXyzByProp(propName, xyzPropName, model);
    const newValue = currentValue + value;

    return setModelXyzByProp(propName, xyzPropName, newValue, model);
  },
);

export const setModelPosition = curry(
  (value: ThreeXyzMap, model: ThreeObj3D) => {
    model.position.set(value.x, value.y, value.z);
    return model;
  },
);

export const setModelRotation = curry(
  (value: ThreeXyzMap, model: ThreeObj3D) => {
    model.rotation.set(value.x, value.y, value.z);
    return model;
  },
);

export const setModelScale = curry((value: number, model: ThreeObj3D) => {
  model.scale.set(value, value, value);
  return model;
});

export const getModelPositionByProp = getModelXyzByProp('position');
export const getModelRotationByProp = getModelXyzByProp('rotation');

export const setModelPositionByProp = setModelXyzByProp('position');
export const setModelRotationByProp = setModelXyzByProp('rotation');

export const incrementModelPositionByProp = incrementModelXyzByProp('position');
export const incrementModelRotationByProp = incrementModelXyzByProp('rotation');

export const moveModelX = setModelPositionByProp('x');
export const moveModelY = setModelPositionByProp('y');
export const moveModelZ = setModelPositionByProp('z');

export const rotateModelX = setModelRotationByProp('x');
export const rotateModelY = setModelRotationByProp('y');
export const rotateModelZ = setModelRotationByProp('z');

export const moveModel = setModelPosition();
export const rotateModel = setModelRotation();
export const scaleModel = setModelScale();
