import { useMemo } from 'react';

import {
  GetBusinessUnits,
  GetBusinessUnit,
  CreateBusinessUnit,
  UpdateBusinessUnit,
  DeleteBusinessUnits,
} from '~/graphql';

import type {
  BusinessUnitsQueryResultData,
  BusinessUnitQueryResultData,
  CreateUpdateBusinessUnitInput,
  CreateUpdateBusinessUnitMutationResultData,
  DeleteBusinessUnitsMutationResultData,
} from '~/types';

import {
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeCreateEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeDeleteEntitiesMutationHook,
  getIlikeFilterValue,
  getInFilterValue,
} from './utils';

export const useGetBusinessUnitsQuery =
  makeGetEntitiesQueryHook<BusinessUnitsQueryResultData>(GetBusinessUnits);

export const useGetBusinessUnitByIdQuery =
  makeGetEntityByIdQueryHook<BusinessUnitQueryResultData>(GetBusinessUnit);

export const useCreateBusinessUnitMutation = makeCreateEntityMutationHook<
  CreateUpdateBusinessUnitMutationResultData,
  CreateUpdateBusinessUnitInput
>(CreateBusinessUnit);

export const useUpdateBusinessUnitMutation = makeUpdateEntityMutationHook<
  CreateUpdateBusinessUnitMutationResultData,
  CreateUpdateBusinessUnitInput
>(UpdateBusinessUnit);

export const useDeleteBusinessUnitsMutation =
  makeDeleteEntitiesMutationHook<DeleteBusinessUnitsMutationResultData>(
    DeleteBusinessUnits,
  );

type SearchBusinessQueryVars = {
  value?: string;
  ids?: string[];
};

export const useSearchBusinessUnitsQuery = ({
  value,
  ids,
}: SearchBusinessQueryVars) => {
  const variables = useMemo(
    () => ({
      filters: {
        or: [
          { valueIlike: getIlikeFilterValue(value) },
          { idIn: getInFilterValue(ids) },
        ],
      },
    }),
    [value, ids],
  );

  return useGetBusinessUnitsQuery(variables);
};
