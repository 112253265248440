import { useMemo } from 'react';

import { GetJobTypes, GetJobType } from '~/graphql';

import type { JobTypesQueryResultData, JobTypeQueryResultData } from '~/types';

import {
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  getIlikeFilterValue,
  getInFilterValue,
} from './utils';

export const useGetJobTypesQuery =
  makeGetEntitiesQueryHook<JobTypesQueryResultData>(GetJobTypes);

export const useGetJobTypeByIdQuery =
  makeGetEntityByIdQueryHook<JobTypeQueryResultData>(GetJobType);

type SearchJobTypeQueryVars = {
  value?: string;
  ids?: string[];
};

export const useSearchJobTypeQuery = ({
  value,
  ids,
}: SearchJobTypeQueryVars) => {
  const variables = useMemo(
    () => ({
      filters: {
        or: [
          { valueIlike: getIlikeFilterValue(value) },
          { idIn: getInFilterValue(ids) },
        ],
      },
    }),
    [value, ids],
  );

  return useGetJobTypesQuery(variables);
};
