import jwt from 'jsonwebtoken';

const { REACT_APP_JWT_SECRET_KEY } = process.env;

export const getIsValidToken = (token?: string) => {

  if (!token || token === "") return false;
  if (!REACT_APP_JWT_SECRET_KEY) return false;

  try {
    jwt.verify(token, REACT_APP_JWT_SECRET_KEY);
    return true;
  } catch {
    return false;
  }
};

// REDIRECT PATH LOCALSTORAGE UTILS

export const getRedirectPathFromLocalStorage = () => {
  const _token = window.localStorage.getItem("redirectPath");
  return _token;
}

export const saveRedirectPathIntoLocalStorage = (redirectPath:string) => {
  window.localStorage.setItem("redirectPath", redirectPath);
}

export const removeRedirectPathFromLocalStorage = () => {
  window.localStorage.removeItem("redirectPath");
}

// TOKEN LOCALSTORAGE UTILS

export const getTokenFromLocalStorage = () => {
  const _token = window.localStorage.getItem("token");
  return _token;
}

export const getTokenFromURLSearchParams = () => {
  const urlParams = window.location.search.slice(1);
  const params = new URLSearchParams(urlParams);
  const _token = params.get("token") as string;

  window.history.pushState({}, document.title, window.location.pathname);

  return _token;
}

export const saveTokenIntoLocalStorage = (token:string) => {
  window.localStorage.setItem("token", token);
}

export const removeTokenFromLocalStorage = () => {
  window.localStorage.removeItem("token");
}
