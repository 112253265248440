import { ContentItemTypeValues, JobTypeValues } from '~/enums';

export const getHumanReadableContentItemType = (
  contentItemType?: ContentItemTypeValues,
): string => {
  switch (contentItemType) {
    case ContentItemTypeValues.AR: {
      return 'AR';
    }
    case ContentItemTypeValues.VR: {
      return 'VR';
    }
    case ContentItemTypeValues.MATTERPORT_SCENE: {
      return 'Matterport Scene';
    }
    case ContentItemTypeValues.MATTERPORT_ASSET: {
      return 'Matterport Asset';
    }
    case ContentItemTypeValues.NEWSLETTER: {
      return 'Publication';
    }
    case ContentItemTypeValues.RENDERED_VT: {
      return 'Rendered VT';
    }
    default: {
      return 'Unknown';
    }
  }
};

export const getContentItemTypeValueFromJobTypeValue = (
  jobTypeValue?: JobTypeValues,
) => {
  switch (jobTypeValue) {
    case JobTypeValues.AR: {
      return ContentItemTypeValues.AR;
    }
    case JobTypeValues.VR: {
      return ContentItemTypeValues.VR;
    }
    case JobTypeValues.NEWSLETTER: {
      return ContentItemTypeValues.NEWSLETTER;
    }
    case JobTypeValues.MATTERPORT: {
      return ContentItemTypeValues.MATTERPORT_SCENE;
    }
    case JobTypeValues.RENDERED_VT: {
      return ContentItemTypeValues.RENDERED_VT;
    }
    default: {
      return ContentItemTypeValues.AR;
    }
  }
};
