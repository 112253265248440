import { FC } from 'react';

import { useAuthTokensContext } from '~/context';

import AsyncContent from '~/components/AsyncContent';

const NonAuthenticatedContent: FC = ({ children }) => {
  const { loading, error } = useAuthTokensContext();

  return (
    <AsyncContent loading={loading} error={error}>
      { children }
    </AsyncContent>
  );
};

export default NonAuthenticatedContent;
