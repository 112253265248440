import { useState, useMemo, useCallback, useEffect } from 'react';

type UseToggleableProps = {
  defaultValue?: boolean;
  onEnable?: () => void;
  onDisable?: () => void;
};

export const useToggleable = ({
  defaultValue = false,
  onEnable,
  onDisable,
}: UseToggleableProps = {}) => {
  const [value, setValue] = useState<boolean>(defaultValue ?? false);

  useEffect(() => setValue(defaultValue), [defaultValue])

  const enable = useCallback(() => {
    setValue(true);
    onEnable?.();
  }, [onEnable]);

  const disable = useCallback(() => {
    setValue(false);
    onDisable?.();
  }, [onDisable]);

  const toggle = useCallback(() => {
    if (value) {
      disable();
    } else {
      enable();
    }
  }, [value, enable, disable]);

  const result = useMemo(
    () => ({ value, setValue, toggle, enable, disable }),
    [value, setValue, enable, disable, toggle],
  );

  return result;
};
