import { gql } from '@kv/apollo-client';

export const VanityUrlFragment = gql`
  fragment VanityUrlFragment on VanityUrl {
    __typename
    id
    value
    redirectTo
    entityId
    metrics
  }
`;

export const GetVanityUrls = gql`
  query GetVanityUrls($filters: VanityUrlFilter) {
    vanityUrls(filters: $filters) {
      ...VanityUrlFragment
    }
  }
  ${VanityUrlFragment}
`;

export const GetVanityUrl = gql`
  query GetVanityUrl($filters: VanityUrlFilter) {
    vanityUrl(filters: $filters) {
      ...VanityUrlFragment
    }
  }
  ${VanityUrlFragment}
`;

export const CreateVanityUrl = gql`
  mutation CreateVanityUrl($input: VanityUrlInput!) {
    createVanityUrl(input: $input) {
      ...VanityUrlFragment
    }
  }
  ${VanityUrlFragment}
`;

export const UpdateVanityUrl = gql`
  mutation UpdateVanityUrl($id: ID!, $input: VanityUrlInput!) {
    updateVanityUrl(id: $id, input: $input) {
      ...VanityUrlFragment
    }
  }
  ${VanityUrlFragment}
`;

export const DeleteVanityUrls = gql`
  mutation DeleteVanityUrls($ids: [ID]) {
    deleteVanityUrls(ids: $ids) {
      ids
    }
  }
`;
