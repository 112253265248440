import { QueryHookOptions, useQuery } from '@kv/apollo-client';

import type { GraphqlNode, BaseQueryVars } from '~/types';

import { parseQueryFilters } from './filters';

export const makeStandardQueryHook = <
  QueryResults,
  QueryVars extends BaseQueryVars = BaseQueryVars,
>(
  queryNode: GraphqlNode<QueryResults, QueryVars>,
) => {
  const useStandardQuery = (
    variables?: QueryVars,
    options?: QueryHookOptions<QueryResults, QueryVars>,
  ) =>
    useQuery<QueryResults, QueryVars>(queryNode, {
      ...options,
      skip: options?.skip,
      variables: {
        ...options?.variables,
        ...variables,
        filters: variables?.filters
          ? parseQueryFilters(variables.filters)
          : undefined,
      } as QueryVars,
    });

  return useStandardQuery;
};
