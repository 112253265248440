import { createSelector } from '@reduxjs/toolkit';
import { pipe, prop, propEq, filter } from 'ramda';

import { SLICE_NAME } from './slice';
import { VisibilityFilters } from './enums';
import type { State, TodosSelector } from './types';

//* We're using `as` here to tell TypeScript that this function takes
//* the root state as its argument specifically and not just any object.
//* That way the other selectors can know for sure whether specific parts
//* of the state slice exist or not.
export const getTodosState = prop(SLICE_NAME) as TodosSelector<State>;

export const getTodosFilter = pipe(getTodosState, prop('visibilityFilter'));

export const getAllTodos = pipe(getTodosState, prop('todos'));

export const getVisibleTodos = createSelector(
  [getAllTodos, getTodosFilter],
  (todos, visibilityFilter) => {
    switch (visibilityFilter) {
      case VisibilityFilters.ALL: {
        return todos;
      }
      case VisibilityFilters.COMPLETED:
      case VisibilityFilters.ACTIVE: {
        const showCompleted = visibilityFilter === VisibilityFilters.COMPLETED;
        return todos.filter(propEq('completed', showCompleted));
      }
      default: {
        console.error(`Unknown filter: ${filter}`);
        return todos;
      }
    }
  },
);
