import { FC, useEffect } from 'react';

import { useToasts } from 'react-toast-notifications';
import { useLocation } from 'react-router-dom';

import { useAuthTokensContext } from '~/context';

import NotAuthenticatedInfo from '../NotAuthenticatedInfo';
import RedirectHandler from '../RedirectHandler';

import { saveRedirectPathIntoLocalStorage } from '../../utils';
import { LoginFailStatus } from '../../enums';

const AuthenticatedContent: FC = ({ children }) => {
  const location = useLocation();
  const { addToast } = useToasts();

  const { isAuthenticated, refetch } = useAuthTokensContext();

  useEffect(() => {
    // save redirect URL
    const parsedSearchParams = new URLSearchParams(location.search);
    const redirectUrl = parsedSearchParams.get('redirectUrl');
    if (redirectUrl) saveRedirectPathIntoLocalStorage(redirectUrl);

    refetch();

    // clear the search params from the url bar
    window.history.replaceState(null, '', window.location.pathname);
  }, [refetch, location]);

  // handle login failure status url param
  useEffect(() => {
    const urlParams = location.search.slice(1);
    const params = new URLSearchParams(urlParams);
    const status = params.get('status') as LoginFailStatus;
    const autoDismissTimeout = 15_000;

    if (!isAuthenticated) return;

    switch (status) {
      case LoginFailStatus.REDIRECT_NOT_ALLOWED:
        addToast('You are not allowed to view this content', {
          appearance: 'warning',
          autoDismissTimeout,
        });
        break;

      case LoginFailStatus.ASSET_NOT_FOUND:
        addToast('The requested content does not exist', {
          appearance: 'warning',
          autoDismissTimeout,
        });
        break;
    }
  }, [location, isAuthenticated, addToast]);

  if (!isAuthenticated) return <NotAuthenticatedInfo />;

  return (
    <>
      <RedirectHandler />
      {children}
    </>
  );
};

export default AuthenticatedContent;
