import { gql } from '@kv/apollo-client';

export const GetAuthTokens = gql`
  query GetAuthTokens {
    authTokens {
      accessToken
      refreshToken
    }
  }
`;

export const UserLogin = gql`
  mutation UserLogin($input: UserLoginInput!) {
    userLogin(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const UserLogout = gql`
  mutation UserLogout {
    userLogout {
      error {
        code
        message
      }
    }
  }
`;

export const RefreshAccessToken = gql`
  mutation RefreshAccessToken {
    refreshAccessToken {
      accessToken
    }
  }
`;
