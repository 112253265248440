import ReactDOM from 'react-dom';

import App from './components/App';
import { getIsProd } from './utils';

import reportWebVitals from './reportWebVitals';
import {
  registerServiceWorker,
  unregisterServiceWorker,
} from './serviceWorker';

import './index.scss';

declare global {
  interface Window {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    MP_SDK: any;
    SHOWCASE_SDK: any;
    /* eslint-enable @typescript-eslint/no-explicit-any */
  }
}

ReactDOM.render(<App />, document.querySelector('#root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (getIsProd()) {
  registerServiceWorker();
} else {
  unregisterServiceWorker();
}
