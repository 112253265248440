import { FC, useMemo } from 'react';
import type { ApolloError } from '@kv/apollo-client';

import { getApolloErrorMessages } from '~/utils';

type ApolloErrorsProps = {
  error?: ApolloError;
};

const ApolloErrors: FC<ApolloErrorsProps> = ({ error }) => {
  const messages = useMemo(() => getApolloErrorMessages(error), [error]);

  if (messages.length === 0) {
    return null;
  }

  return (
    <>
      {messages.map((message, index) => (
        <p key={`${index}-${message}`}>{message}</p>
      ))}
    </>
  );
};

export default ApolloErrors;
