export const INPUT_DEBOUNCE_DELAY = 500;

export const notRequired = { required: false };


type GlobalUseFormOptions = {
  mode?: "onBlur" | "onChange" | "onSubmit",
  shouldUnregister?: boolean,
  defaultValues?: Record<string, unknown>
}

export const globalUseFormOptions:GlobalUseFormOptions = {
  mode: 'onChange',
  shouldUnregister: true,
  defaultValues: {},
}
