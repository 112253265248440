import { useMemo } from 'react';

import {
  GetUserGroups,
  GetUserGroup,
  CreateUserGroup,
  UpdateUserGroup,
  DeleteUserGroups,
} from '~/graphql';

import type {
  UserGroupsQueryResultData,
  UserGroupQueryResultData,
  CreateUpdateUserGroupInput,
  CreateUpdateUserGroupMutationResultData,
  DeleteUserGroupsMutationResultData,
} from '~/types';

import {
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeCreateEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeDeleteEntitiesMutationHook,
  getIlikeFilterValue,
  getInFilterValue,
} from './utils';

export const useGetUserGroupsQuery =
  makeGetEntitiesQueryHook<UserGroupsQueryResultData>(GetUserGroups);

export const useGetUserGroupByIdQuery =
  makeGetEntityByIdQueryHook<UserGroupQueryResultData>(GetUserGroup);

export const useCreateUserGroupMutation = makeCreateEntityMutationHook<
  CreateUpdateUserGroupMutationResultData,
  CreateUpdateUserGroupInput
>(CreateUserGroup);

export const useUpdateUserGroupMutation = makeUpdateEntityMutationHook<
  CreateUpdateUserGroupMutationResultData,
  CreateUpdateUserGroupInput
>(UpdateUserGroup);

export const useDeleteUserGroupsMutation =
  makeDeleteEntitiesMutationHook<DeleteUserGroupsMutationResultData>(
    DeleteUserGroups,
  );

type SearchUserGroupQueryVars = {
  value?: string;
  ids?: string[];
};

export const useSearchUserGroupsQuery = ({
  value,
  ids,
}: SearchUserGroupQueryVars) => {
  const variables = useMemo(
    () => ({
      filters: {
        or: [
          { valueIlike: getIlikeFilterValue(value) },
          { idIn: getInFilterValue(ids) },
        ],
      },
    }),
    [value, ids],
  );

  return useGetUserGroupsQuery(variables);
};
