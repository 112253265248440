const { REACT_APP_MATTERPORT_APPLICATION_KEY } = process.env;

export const connectMatterportSdk = async (
  iframe: HTMLIFrameElement | null,
) => {
  if (!iframe) {
    return {
      sdk: undefined,
      error: 'iframe is not defined',
    };
  }

  if (!iframe.contentWindow) {
    return {
      sdk: undefined,
      error: 'iframe.contentWindow is not defined',
    };
  }

  if (!REACT_APP_MATTERPORT_APPLICATION_KEY) {
    return {
      sdk: undefined,
      error: 'REACT_APP_MATTERPORT_APPLICATION_KEY is not defined',
    };
  }

  try {
    const sdk = await iframe.contentWindow.MP_SDK.connect(
      iframe,
      REACT_APP_MATTERPORT_APPLICATION_KEY,
      '3.7',
    );
    return {
      sdk,
      error: undefined,
    };
  } catch (error) {
    return {
      sdk: undefined,
      error,
    };
  }
};
