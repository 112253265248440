import { addWeeks, addMonths, endOfDay } from 'date-fns/fp';
import { last } from 'ramda';

import type { SelectOption, ContentItem } from '~/types';

import { getSelectOptionFromNamedEntity } from './forms';

export const expiresOptions: SelectOption[] = [
  {
    id: '1 Week',
    value: '1 Week',
  },
  {
    id: '1 Month',
    value: '1 Month',
  },
  {
    id: '2 Months',
    value: '2 Months',
  },
  {
    id: '3 Months',
    value: '3 Months',
  },
  {
    id: 'Specify Date',
    value: 'Specify Date',
  },
];

export const specifyDateExpiresOption = last(expiresOptions) as SelectOption;

export const getBaseExpirationDateFromExpiresOption = (value = 'Never') => {
  const currentDate = new Date();

  switch (value) {
    case 'Never': {
      return undefined;
    }

    case '1 Week': {
      return addWeeks(1, currentDate);
    }

    case '1 Month': {
      return addMonths(1, currentDate);
    }

    case '2 Months': {
      return addMonths(2, currentDate);
    }

    case '3 Months': {
      return addMonths(3, currentDate);
    }

    case 'Specify Date': {
      return currentDate;
    }
  }
};

export const getDefaultContentItemOption = (contentItem?: ContentItem) =>
  contentItem ? getSelectOptionFromNamedEntity(contentItem) : null;

export const getDefaultExpiresOptionFromExpirationDate = (
  expirationDate?: Date,
) => (expirationDate ? specifyDateExpiresOption : null);

export const getExpirationDateFromExpiresOption = (value?: string) => {
  const baseExpirationDate = getBaseExpirationDateFromExpiresOption(value);

  return baseExpirationDate ? endOfDay(baseExpirationDate) : undefined;
};
