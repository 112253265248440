import { useMemo, useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';
import { kebabCase, capitalize } from 'lodash';
import type { ApolloError } from '@kv/apollo-client';

import { getMessageFromApolloError } from '~/utils';

type CrudAction = 'create' | 'update' | 'delete';
type CrudActionPresentTense = 'creating' | 'updating' | 'deleting';
type CrudActionPastTense = 'created' | 'updated' | 'deleted';
type ToastType = 'in-progress' | 'success' | 'error';

const getPresentTenseActionVerb = (
  action: CrudAction,
): CrudActionPresentTense => {
  switch (action) {
    case 'create': {
      return 'creating';
    }
    case 'update': {
      return 'updating';
    }
    case 'delete': {
      return 'deleting';
    }
  }
};

const getPastTenseActionVerb = (action: CrudAction): CrudActionPastTense => {
  switch (action) {
    case 'create': {
      return 'created';
    }
    case 'update': {
      return 'updated';
    }
    case 'delete': {
      return 'deleted';
    }
  }
};

export const useApolloCrudToasts = (entityName: string) => {
  const { addToast, removeToast } = useToasts();

  const toastIdPrefix = useMemo(() => kebabCase(entityName), [entityName]);

  const getToastId = useCallback(
    (action: CrudAction, toastType: ToastType) =>
      `${toastIdPrefix}-${action}-${toastType}`,
    [toastIdPrefix],
  );

  const addInProgressToast = useCallback(
    (action: CrudAction) => {
      const actionVerb = getPresentTenseActionVerb(action);

      addToast(`${capitalize(actionVerb)} ${entityName}...`, {
        id: getToastId(action, 'in-progress'),
        appearance: 'info',
        autoDismiss: false,
      });
    },
    [addToast, entityName, getToastId],
  );

  const removeInProgressToast = useCallback(
    (action: CrudAction) => {
      removeToast(getToastId(action, 'in-progress'));
    },
    [removeToast, getToastId],
  );

  const addSuccessToast = useCallback(
    (action: CrudAction) => {
      const actionVerb = getPastTenseActionVerb(action);
      const indicative = entityName.endsWith('s') ? 'were' : 'was';

      addToast(`${entityName} ${indicative} ${actionVerb}`, {
        id: getToastId(action, 'success'),
        appearance: 'success',
      });
      removeInProgressToast(action);
    },
    [addToast, entityName, getToastId, removeInProgressToast],
  );

  const addErrorToast = useCallback(
    (error: ApolloError) => {
      const message = getMessageFromApolloError(error);

      removeInProgressToast('create');
      removeInProgressToast('update');
      removeInProgressToast('delete');
      addToast(message, { appearance: 'error' });
    },
    [addToast, removeInProgressToast],
  );

  const result = useMemo(
    () => ({
      addInProgressToast,
      addSuccessToast,
      addErrorToast,
    }),
    [addInProgressToast, addSuccessToast, addErrorToast],
  );

  return result;
};
