import { configureStore } from '@reduxjs/toolkit';
import { createSelectorHook } from 'react-redux';

import rootReducer, { RootState } from './reducers';

const store = configureStore({
  reducer: rootReducer,
});

export type { RootState };

export const useSelector = createSelectorHook<RootState>();

export default store;
