import { gql } from '@kv/apollo-client';

export const BusinessUnitFragment = gql`
  fragment BusinessUnitFragment on BusinessUnit {
    __typename
    id
    value
  }
`;

export const GetBusinessUnits = gql`
  query GetBusinessUnits($filters: BusinessUnitFilter) {
    businessUnits(filters: $filters) {
      ...BusinessUnitFragment
    }
  }
  ${BusinessUnitFragment}
`;

export const GetBusinessUnit = gql`
  query GetBusinessUnit {
    businessUnit {
      ...BusinessUnitFragment
    }
  }
  ${BusinessUnitFragment}
`;

export const CreateBusinessUnit = gql`
  mutation CreateBusinessUnit($input: BusinessUnitInput!) {
    createBusinessUnit(input: $input) {
      ...BusinessUnitFragment
    }
  }
  ${BusinessUnitFragment}
`;

export const UpdateBusinessUnit = gql`
  mutation UpdateBusinessUnit($id: ID!, $input: BusinessUnitInput!) {
    updateBusinessUnit(id: $id, input: $input) {
      ...BusinessUnitFragment
    }
  }
  ${BusinessUnitFragment}
`;

export const DeleteBusinessUnits = gql`
  mutation DeleteBusinessUnits($ids: [ID]) {
    deleteBusinessUnits(ids: $ids) {
      ids
    }
  }
`;
