import { propEq } from 'ramda';

import type { User, Job } from '~/types';
import { UserRoleValues } from '~/enums';

export const getIsAdminUser = (user?: User) =>
  user?.role?.value === UserRoleValues.ADMIN;

export const canUserEditJob = (user?: User, job?: Job) => {
  if (getIsAdminUser(user)) {
    return true;
  }

  const editorUsers = job?.editorUsers ?? [];

  return editorUsers.some(propEq('id', user?.id));
};
