import { useMemo } from 'react';
import { QueryHookOptions } from '@kv/apollo-client';

import {
  GetContentItemTypes,
  GetContentItemType,
} from '~/graphql';

import type {
  ContentItemTypesQueryResultData,
  ContentItemTypeQueryResultData,
} from '~/types';

import {
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  getIlikeFilterValue,
  getInFilterValue,
} from './utils';

export const useGetContentItemTypesQuery =
  makeGetEntitiesQueryHook<ContentItemTypesQueryResultData>(
    GetContentItemTypes,
  );

export const useGetContentItemTypeByIdQuery =
  makeGetEntityByIdQueryHook<ContentItemTypeQueryResultData>(
    GetContentItemType,
  );

type SearchContentItemsTypesQueryVars = {
  value?: string;
  ids?: string[];
};

export const useSearchContentItemTypesQuery = (
  { value, ids }: SearchContentItemsTypesQueryVars,
  options?: QueryHookOptions<ContentItemTypesQueryResultData>,
) => {
  const variables = useMemo(
    () => ({
      filters: {
        or: [
          { valueIlike: getIlikeFilterValue(value) },
          { idIn: getInFilterValue(ids) },
        ],
      },
    }),
    [value, ids],
  );

  return useGetContentItemTypesQuery(variables, options);
};
