import { FC } from 'react';
import type { ApolloError } from '@kv/apollo-client';

import LoadingIndicator from '~/components/LoadingIndicator';
import ApolloErrors from '~/components/ApolloErrors';

export type AsyncContentProps = {
  loading?: boolean;
  error?: ApolloError;
};

const AsyncContent: FC<AsyncContentProps> = ({ children, loading, error }) => {
  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <ApolloErrors error={error} />;
  }

  return <>{children}</>;
};

export default AsyncContent;
