import { gql } from '@kv/apollo-client';

export const JobTypeFragment = gql`
  fragment JobTypeFragment on JobType {
    __typename
    id
    value
  }
`;

export const GetJobTypes = gql`
  query GetJobTypes($filters: JobTypeFilter) {
    jobTypes(filters: $filters) {
      ...JobTypeFragment
    }
  }
  ${JobTypeFragment}
`;

export const GetJobType = gql`
  query GetJobType {
    jobType {
      ...JobTypeFragment
    }
  }
  ${JobTypeFragment}
`;
