export const SERVER_URL = '/server';
export const SERVER_ASSETS_URL = `${SERVER_URL}/public`;
export const GRAPHQL_URL = `${SERVER_URL}/graphql`;

export const BD_SSO_LOGIN_URL = `${SERVER_URL}/sso-login`;
export const CIAM_SSO_LOGIN_URL = `${SERVER_URL}/ciam-sso-login`;

export const DEFAULT_THUMBNAIL_URL = `${SERVER_ASSETS_URL}/images/thumbnail-default.png`;

/*
NOTE: these are no longer used - keeping for posterity
*/
// export const BD_SSO_LOGOUT_URL = `${SERVER_URL}/sso-logout`;
// export const CIAM_SSO_LOGOUT_URL = `${SERVER_URL}/ciam-sso-logout`;
