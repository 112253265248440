import { gql } from '@kv/apollo-client';

export const TagFragment = gql`
  fragment TagFragment on Tag {
    __typename
    id
    value
  }
`;

export const GetTags = gql`
  query GetTags($filters: TagFilter) {
    tags(filters: $filters) {
      ...TagFragment
    }
  }
  ${TagFragment}
`;

export const GetTag = gql`
  query GetTag($filters: TagFilter) {
    tag(filters: $filters) {
      ...TagFragment
    }
  }
  ${TagFragment}
`;

export const CreateTag = gql`
  mutation CreateTag($input: TagInput!) {
    createTag(input: $input) {
      ...TagFragment
    }
  }
  ${TagFragment}
`;

export const UpdateTag = gql`
  mutation UpdateTag($id: ID!, $input: TagInput!) {
    updateTag(id: $id, input: $input) {
      ...TagFragment
    }
  }
  ${TagFragment}
`;

export const DeleteTags = gql`
  mutation DeleteTags($ids: [ID]) {
    deleteTags(ids: $ids) {
      ids
    }
  }
`;
