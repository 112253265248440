import { css } from 'astroturf/react';

export const flexColumn = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const borderRadius = css`
  border-radius: 5px;
`;

export const fontSizeDefault = css`
  font-size: 16px;
`;

export const lineHeightDefault = css`
  line-height: 24px;
`;

export const heightButton = css`
  height: 36px;
`;

export const fontSizeButton = css`
  font-size: 14.6667px;
`;

export const lineHeightButton = css`
  line-height: 16px;
`;

export const heightInput = css`
  composes: ${heightButton};
`;

export const fontSizeInput = css`
  composes: ${fontSizeButton};
`;

export const lineHeightInput = css`
  line-height: 34px;
`;

export const lineHeightInputLabel = css`
  line-height: 36px;
`;

export const borderInput = css`
  border: 1px solid #999;
`;

export const backgroundColorInput = css`
  background-color: #fff;
`;

export const inputDefaults = css`
  composes: ${borderRadius};
  composes: ${fontSizeInput};
  composes: ${lineHeightInput};
  composes: ${borderInput};
  composes: ${backgroundColorInput};

  display: block;
  width: 100%;
  padding: 0 8px;
  appearance: none;
  outline: none;
`;
