import { useMemo } from 'react';
import { useQuery, QueryHookOptions } from '@kv/apollo-client';

import {
  GetUsers,
  GetUser,
  GetCurrentUser,
  CreateUser,
  UpdateUser,
  DeleteUsers,
} from '~/graphql';

import type {
  UsersQueryResultData,
  UserQueryResultData,
  CurrentUserQueryResultData,
  CreateUpdateUserInput,
  CreateUpdateUserMutationResultData,
  DeleteUsersMutationResultData,
} from '~/types';

import {
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeCreateEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeDeleteEntitiesMutationHook,
  getIlikeFilterValue,
  getInFilterValue,
} from './utils';

export const useGetUsersQuery =
  makeGetEntitiesQueryHook<UsersQueryResultData>(GetUsers);

export const useGetUserByIdQuery =
  makeGetEntityByIdQueryHook<UserQueryResultData>(GetUser);

export const useGetCurrentUserQuery = () =>
  useQuery<CurrentUserQueryResultData>(GetCurrentUser);

export const useCreateUserMutation = makeCreateEntityMutationHook<
  CreateUpdateUserMutationResultData,
  CreateUpdateUserInput
>(CreateUser);

export const useUpdateUserMutation = makeUpdateEntityMutationHook<
  CreateUpdateUserMutationResultData,
  CreateUpdateUserInput
>(UpdateUser);

export const useDeleteUsersMutation =
  makeDeleteEntitiesMutationHook<DeleteUsersMutationResultData>(DeleteUsers);

type SearchUserQueryVars = {
  name?: string;
  ids?: string[];
  email?: string;
  nameIds?: string[];
  emailIds?: string[];
  roleIds?: string[];
  groupIds?: string[];
};

export const useSearchUsersQuery = (
  {
    name,
    ids,
    email,
    nameIds,
    emailIds,
    roleIds,
    groupIds,
  }: SearchUserQueryVars,
  options?: QueryHookOptions<UsersQueryResultData>,
) => {
  const variables = useMemo(
    () => ({
      filters: {
        or: [
          { nameIlike: getIlikeFilterValue(name) },
          { emailIlike: getIlikeFilterValue(email) },
          { idIn: getInFilterValue(ids) },
          { idIn: getInFilterValue(nameIds) },
          { idIn: getInFilterValue(emailIds) },
          { roleIdIn: getInFilterValue(roleIds) },
          { groupIdsIn: getInFilterValue(groupIds) },
        ],
      },
    }),
    [name, ids, email, nameIds, emailIds, roleIds, groupIds],
  );

  return useGetUsersQuery(variables, options);
};
