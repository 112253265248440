import { createApolloClient } from '@kv/apollo-client';

import { GRAPHQL_URL } from '~/constants';

export const apolloClient = createApolloClient({
  linkOptions: {
    uri: GRAPHQL_URL,
  },
  clientDefaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});
