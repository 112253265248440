import { useMemo } from 'react';
import { QueryHookOptions } from '@kv/apollo-client';

import {
  GetJobs,
  GetJob,
  CreateJob,
  UpdateJob,
  DeleteJobs,
} from '~/graphql';

import type {
  JobsQueryResultData,
  JobQueryResultData,
  CreateUpdateJobInput,
  CreateUpdateJobMutationResultData,
} from '~/types';

import {
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeCreateEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeDeleteEntitiesMutationHook,
  getIlikeFilterValue,
  getInFilterValue,
} from './utils';

export const useGetJobsQuery =
  makeGetEntitiesQueryHook<JobsQueryResultData>(GetJobs);

export const useGetJobByIdQuery =
  makeGetEntityByIdQueryHook<JobQueryResultData>(GetJob);

export const useCreateJobMutation = makeCreateEntityMutationHook<
  CreateUpdateJobMutationResultData,
  CreateUpdateJobInput
>(CreateJob);

export const useUpdateJobMutation = makeUpdateEntityMutationHook<
  CreateUpdateJobMutationResultData,
  CreateUpdateJobInput
>(UpdateJob);

export const useDeleteJobsMutation =
  makeDeleteEntitiesMutationHook<CreateUpdateJobMutationResultData>(DeleteJobs);

type SearchJobsQueryVars = {
  name?: string;
  ids?: string[];
  businessUnitIds?: string[];
  jobTypeIds?: string[];
  tagIds?: string[];
};

export const useSearchJobsQuery = (
  { name, ids, businessUnitIds, jobTypeIds, tagIds }: SearchJobsQueryVars,
  options?: QueryHookOptions,
) => {
  const variables = useMemo(
    () => ({
      filters: {
        or: [
          { nameIlike: getIlikeFilterValue(name) },
          { idIn: getInFilterValue(ids) },
          { businessUnitIdIn: getInFilterValue(businessUnitIds) },
          { jobTypeIdIn: getInFilterValue(jobTypeIds) },
          { tagIdsIn: getInFilterValue(tagIds) },
        ],
      },
    }),
    [name, ids, businessUnitIds, jobTypeIds, tagIds],
  );

  return useGetJobsQuery(variables, options);
};
