import { gql } from '@kv/apollo-client';

import { BusinessUnitFragment } from './businessUnits';

import { JobItemFragment } from './jobItems';
import { JobTypeFragment } from './jobTypes';
import { TagFragment } from './tags';

export const JobFragment = gql`
  fragment JobFragment on Job {
    __typename
    id
    name
    businessUnit {
      ...BusinessUnitFragment
    }
    tags {
      ...TagFragment
    }
    jobType {
      ...JobTypeFragment
    }
    jobItems {
      ...JobItemFragment
    }
    editorUsers {
      id
      name
    }
  }
  ${BusinessUnitFragment}
  ${JobTypeFragment}
  ${JobItemFragment}
  ${TagFragment}
`;

export const GetJobs = gql`
  query GetJobs($filters: JobFilter) {
    jobs(filters: $filters) {
      ...JobFragment
    }
  }
  ${JobFragment}
`;

export const GetJob = gql`
  query GetJob($id: ID!) {
    job(id: $id) {
      ...JobFragment
    }
  }
  ${JobFragment}
`;

export const CreateJob = gql`
  mutation CreateJob($input: JobInput!) {
    createJob(input: $input) {
      ...JobFragment
    }
  }
  ${JobFragment}
`;

export const UpdateJob = gql`
  mutation UpdateJob($id: ID!, $input: JobInput!) {
    updateJob(id: $id, input: $input) {
      ...JobFragment
    }
  }
  ${JobFragment}
`;

export const DeleteJobs = gql`
  mutation DeleteJobs($ids: [ID]) {
    deleteJobs(ids: $ids) {
      ids
    }
  }
`;
