import { useMemo } from 'react';
import { QueryHookOptions } from '@kv/apollo-client';

import {
  GetContentItems,
  GetContentItem,
  CreateContentItem,
  UpdateContentItem,
  DeleteContentItems,
} from '~/graphql';

import type {
  ContentItemsQueryResultData,
  ContentItemQueryResultData,
  CreateUpdateContentItemInput,
  CreateUpdateContentItemMutationResultData,
  DeleteContentItemsMutationResultData,
} from '~/types';

import {
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeCreateEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeDeleteEntitiesMutationHook,
  getInFilterValue,
  getIlikeFilterValue,
} from './utils';

export const useGetContentItemsQuery =
  makeGetEntitiesQueryHook<ContentItemsQueryResultData>(GetContentItems);

export const useGetContentItemByIdQuery =
  makeGetEntityByIdQueryHook<ContentItemQueryResultData>(GetContentItem);

type SearchContentItemsByTypeQueryVars = {
  contentItemTypeId?: string;
  name?: string;
  ids?: string[];
};

export const useSearchContentItemsQuery = (
  { contentItemTypeId, name, ids }: SearchContentItemsByTypeQueryVars,
  options?: QueryHookOptions<ContentItemsQueryResultData>,
) => {
  const variables = useMemo(
    () => ({
      filters: {
        contentItemTypeId,
        nameIlike: getIlikeFilterValue(name),
        idIn: getInFilterValue(ids),
      },
    }),
    [contentItemTypeId, ids, name],
  );

  return useGetContentItemsQuery(variables, options);
};

export const useCreateContentItemMutation = makeCreateEntityMutationHook<
  CreateUpdateContentItemMutationResultData,
  CreateUpdateContentItemInput
>(CreateContentItem);

export const useUpdateContentItemMutation = makeUpdateEntityMutationHook<
  CreateUpdateContentItemMutationResultData,
  CreateUpdateContentItemInput
>(UpdateContentItem);

export const useDeleteContentItemsMutation =
  makeDeleteEntitiesMutationHook<DeleteContentItemsMutationResultData>(
    DeleteContentItems,
  );
