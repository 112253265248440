import { gql } from '@kv/apollo-client';

import { UserRoleFragment } from './userRoles';
import { UserGroupFragment } from './userGroups';
import { JobFragment } from './jobs';
import { BusinessUnitFragment } from './businessUnits';

export const UserFragment = gql`
  fragment UserFragment on User {
    __typename
    id
    name
    email
    isEnabled
    isBdSsoUser
    isCiamSsoUser
    lastActiveAt
    expiresAt
    role {
      ...UserRoleFragment
    }
    groups {
      ...UserGroupFragment
    }
    businessUnits {
      ...BusinessUnitFragment
    }
    editableJobs {
      ...JobFragment
    }
  }
  ${UserRoleFragment}
  ${UserGroupFragment}
  ${BusinessUnitFragment}
  ${JobFragment}
`;

export const GetUsers = gql`
  query GetUsers($filters: UserFilter) {
    users(filters: $filters) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const GetUser = gql`
  query GetUser {
    user {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const CreateUser = gql`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const UpdateUser = gql`
  mutation UpdateUser($id: ID!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const DeleteUsers = gql`
  mutation DeleteUsers($ids: [ID]) {
    deleteUsers(ids: $ids) {
      ids
    }
  }
`;

export const GetCurrentUser = gql`
  query GetCurrentUser {
    currentUser {
      ...UserFragment
    }
  }
  ${UserFragment}
`;
