import { useCallback } from 'react';
import { MutationHookOptions } from '@kv/apollo-client';

import { useAuthTokensContext } from '~/context/AuthTokens';
import type {
  EntityBase,
  GraphqlNode,
  CreateEntityMutationVars,
  UpdateEntityMutationVars,
  DeleteEntityMutationVars,
  DeleteEntitiesMutationVars,
} from '~/types';

import { MutationWithStateArgs, useMutationWithState } from './mutations';

export const useAuthenticatedMutationWithState = <
  MutationResultsData,
  MutationVars,
  MutationArgs extends unknown[],
>({
  mutationNode,
  options,
  getMutationVars,
}: MutationWithStateArgs<MutationResultsData, MutationVars, MutationArgs>) => {
  const { authTokens } = useAuthTokensContext();

  const getAuthenticatedMutationVars = useCallback(
    (...args: MutationArgs) => ({
      ...getMutationVars(...args),
      token: authTokens?.accessToken,
    }),
    [getMutationVars, authTokens?.accessToken],
  );

  const mutationWithStateTuple = useMutationWithState<
    MutationResultsData,
    MutationVars,
    MutationArgs
  >({
    mutationNode,
    options,
    getMutationVars: getAuthenticatedMutationVars,
  });

  return mutationWithStateTuple;
};

export const makeCreateEntityMutationHook = <
  MutationResultsData,
  MutationInput,
>(
  mutationNode: GraphqlNode<
    MutationResultsData,
    CreateEntityMutationVars<MutationInput>
  >,
) => {
  const useCreateEntityMutation = (
    options?: MutationHookOptions<
      MutationResultsData,
      CreateEntityMutationVars<MutationInput>
    >,
  ) => {
    const getMutationVars = useCallback(
      (input: MutationInput) => ({ input }),
      [],
    );

    const mutationWithStateTuple = useAuthenticatedMutationWithState<
      MutationResultsData,
      CreateEntityMutationVars<MutationInput>,
      [MutationInput]
    >({
      mutationNode,
      options,
      getMutationVars,
    });

    return mutationWithStateTuple;
  };

  return useCreateEntityMutation;
};

export const makeUpdateEntityMutationHook = <
  MutationResultsData,
  MutationInput,
>(
  mutationNode: GraphqlNode<
    MutationResultsData,
    UpdateEntityMutationVars<MutationInput>
  >,
) => {
  const useUpdateEntityMutation = (
    options?: MutationHookOptions<
      MutationResultsData,
      UpdateEntityMutationVars<MutationInput>
    >,
  ) => {
    const getMutationVars = useCallback(
      (entity: EntityBase, input: MutationInput) => ({
        id: entity.id,
        input,
      }),
      [],
    );

    const mutationWithStateTuple = useAuthenticatedMutationWithState<
      MutationResultsData,
      UpdateEntityMutationVars<MutationInput>,
      [EntityBase, MutationInput]
    >({
      mutationNode,
      options,
      getMutationVars,
    });

    return mutationWithStateTuple;
  };

  return useUpdateEntityMutation;
};

export const makeDeleteEntityMutationHook = <MutationResultsData>(
  mutationNode: GraphqlNode<MutationResultsData, DeleteEntityMutationVars>,
) => {
  const useDeleteEntitiesMutation = (
    options?: MutationHookOptions<
      MutationResultsData,
      DeleteEntityMutationVars
    >,
  ) => {
    const getMutationVars = useCallback((id: string) => ({ id }), []);

    const mutationWithStateTuple = useAuthenticatedMutationWithState<
      MutationResultsData,
      DeleteEntityMutationVars,
      [string]
    >({
      mutationNode,
      options,
      getMutationVars,
    });

    return mutationWithStateTuple;
  };

  return useDeleteEntitiesMutation;
};

export const makeDeleteEntitiesMutationHook = <MutationResultsData>(
  mutationNode: GraphqlNode<MutationResultsData, DeleteEntitiesMutationVars>,
) => {
  const useDeleteEntitiesMutation = (
    options?: MutationHookOptions<
      MutationResultsData,
      DeleteEntitiesMutationVars
    >,
  ) => {
    const getMutationVars = useCallback((ids: string[]) => ({ ids }), []);

    const mutationWithStateTuple = useAuthenticatedMutationWithState<
      MutationResultsData,
      DeleteEntitiesMutationVars,
      [string[]]
    >({
      mutationNode,
      options,
      getMutationVars,
    });

    return mutationWithStateTuple;
  };

  return useDeleteEntitiesMutation;
};
