import type { FC, MouseEventHandler } from 'react';

import type { TextSize, TextWeight, StyledComponentAs } from '~/types';

import { StyledText } from './StyledComponents';

type TextProps = {
  className?: string;
  size?: TextSize;
  weight?: TextWeight;
  onClick?: MouseEventHandler;
  inline?: boolean;
  as?: StyledComponentAs;
  'data-testid'?: string;
};

const Text: FC<TextProps> = ({
  className,
  children,
  onClick,
  size = 'body',
  weight = 'normal',
  inline = false,
  as,
  'data-testid': dataTestId,
}) => (
  <StyledText
    className={className}
    as={as}
    size={size}
    weight={weight}
    inline={inline}
    onClick={onClick}
    data-testid={dataTestId}
  >
    {children}
  </StyledText>
);

export default Text;
