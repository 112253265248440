import type {
  ThreeObj3D,
  MatterportSDK,
  ThreeXyzMap,
  MatterportAssetItem,
} from '~/types';

import { moveModel, rotateModel, scaleModel, pickXyzMapProps } from './models';

import { createSceneNode } from './nodes';

export const setupThreeScene = async (sdk: MatterportSDK) => {
  // https://matterport.github.io/showcase-sdk/sdkbundle_tutorials_models.html
  const ambientLight = await sdk.Scene.createNode();

  const ambientLightOptions = {
    enabled: true,
    color: { r: 1, g: 1, b: 1 },
    intensity: 1.2,
  };

  ambientLight.addComponent('mp.ambientLight', ambientLightOptions);
  ambientLight.start();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  await sdk.Scene.configure((renderer: any, three: any) => {
    renderer.physicallyCorrectLights = true;
    renderer = new three.WebGLRenderer({ antialias: true, alpha: true });
    renderer.outputEncoding = three.GammaEncoding;
    renderer.gammaFactor = 2.2;
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
  });
};

export const isRootNode = (obj3D: ThreeObj3D) => obj3D?.isScene;

export const getParentNode = (obj3D: ThreeObj3D): ThreeObj3D | null =>
  obj3D?.parent ?? null;

export const getRootSceneFromNode = (obj3D: ThreeObj3D): ThreeObj3D | null => {
  if (obj3D.isScene) {
    return obj3D;
  }

  const parentNode = getParentNode(obj3D);

  if (parentNode) {
    return getRootSceneFromNode(parentNode);
  }

  return null;
};

export const getThreeScene = async (
  sdk: MatterportSDK,
): Promise<ThreeObj3D | null> => {
  // create and start a dummy node
  const node = await createSceneNode(sdk);

  if (!node || !sdk) return null;

  node.start();
  const scene = getRootSceneFromNode(node.obj3D);

  // clean up
  node.stop();

  return scene;
};

export const getAllContentItemModels = async (SDK: MatterportSDK) =>
  getThreeScene(SDK).then(scene => {
    const _contentItemModels: ThreeObj3D[] = [];

    if (scene) {
      scene.traverse((obj: ThreeObj3D) => {
        if (obj.userData.modelId) {
          _contentItemModels.push(obj);
        }
      });
    }

    return _contentItemModels;
  });

export const findModelById = async (SDK: MatterportSDK, modelId: string) =>
  getAllContentItemModels(SDK).then((_allModels: ThreeObj3D[]) =>
    _allModels.find(_m => _m.userData?.modelId === modelId),
  );

export const getMatterportAssetDataFromModel = (
  model: ThreeObj3D,
): MatterportAssetItem => {
  const { modelId = '', modelUrl = '', name = '' } = model.userData;
  const position: ThreeXyzMap = pickXyzMapProps(model.position);
  const rotation: ThreeXyzMap = pickXyzMapProps(model.rotation);
  const scale = model.scale.x;

  return {
    modelId,
    modelUrl,
    name,
    position,
    rotation,
    scale,
  };
};

export const getSceneAssetData = async (SDK: MatterportSDK) =>
  getAllContentItemModels(SDK).then((_allModels: ThreeObj3D[]) =>
    _allModels.map(model => getMatterportAssetDataFromModel(model)),
  );

export const setModelOrientation3D = (
  model: ThreeObj3D,
  position?: ThreeXyzMap,
  rotation?: ThreeXyzMap,
  scale?: ThreeXyzMap,
) => {
  if (position) {
    moveModel(position, model);
  }

  if (rotation) {
    rotateModel(rotation, model);
  }

  if (scale) {
    scaleModel(scale.x, model);
  }
};
