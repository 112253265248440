import { FC } from 'react';

import AppRoutes from '~/pages';

import AppWrapper from './AppWrapper';
import { AppContainer } from './StyledComponents';

type AppProps = {
  className?: string;
};

const App: FC<AppProps> = ({ className }) => (
  <AppWrapper>
    <AppContainer className={className} data-testid="ui_general_app_container">
      <AppRoutes />
    </AppContainer>
  </AppWrapper>
);

export default App;
