export const toCamelCaseFormatter = (value: string) =>
  value
    .toLowerCase()
    .replace(' ', '-')
    .replace('--', '-')
    .replace(/[^\d A-Za-z-]/, '');

export const INPUT_ID = 'vanity-url-input';

export const setInputElementValue = (value: string) => {
  const element = document.querySelector(`#${INPUT_ID}`) as HTMLInputElement;
  if (element) element.value = value;
};

export const getInputElementValue = () => {
  const element = document.querySelector(`#${INPUT_ID}`) as HTMLInputElement;
  if (element) return element.value;
  return null;
};

export const _handleInputChange = (
  event: React.FormEvent<HTMLInputElement>,
) => {
  const _formattedInput = toCamelCaseFormatter(event.currentTarget.value);
  event.currentTarget.value = _formattedInput;
  return _formattedInput;
};

/**
 * Metrics Modal
 */

export const getCategoryByKey = (key: string) => {
  if (key === 'total') return 'total';
  if (key.split('.').length === 1 && key !== 'total') return 'year';
  if (key.split('.').length === 2) return 'month';
  if (key.split('.').length === 3) return 'day';
  return 'No Category';
};

export const getOrderByKey = (key: string) => {
  if (key === 'total') return 1;
  if (key.split('.').length === 1 && key !== 'total') return 2;
  if (key.split('.').length === 2) return 3;
  if (key.split('.').length === 3) return 4;
  return 5;
};

export const toSlashedDate = (dottedDate: string) =>
  dottedDate.replaceAll('.', '/');
