import { SetStateAction, useState, useMemo, useCallback } from 'react';
import { tap } from 'ramda';
import { debounce } from 'lodash';

export type SetStateFp<A> = (a: SetStateAction<A>) => A;

/**
 * The `setState` function returned by `useState` always returns `undefined`,
 * which means you can't compose `setState` with other functions. This custom
 * hook returns a tapped version of `setState` as the setter that returns the
 * same value that was passed to it (i.e. `x => { setState(x); return x; }`)
 *
 * @usage
 * ```jsx
 * const [user, setUser] = useStateFp(initialUserState);
 * const updateUser = useCallback(
 *   newUserData => pipe(
 *     merge(user),
 *     setUser,
 *     displayToastWithUserData,
 *   )(newUserData),
 *   [setState]
 * );
 * ```
 */
export const useStateFp = <T>(initialState: T): [T, SetStateFp<T>] => {
  const [state, _setState] = useState(initialState);
  const setState = tap(_setState) as SetStateFp<T>;

  return [state, setState];
};

export const useStateDebounced = <T>(
  waitAmount: number,
  initialState: T,
): [T, (newState: T) => void, () => void] => {
  const [state, setState] = useState(initialState);

  const setStateDebounced = useMemo(
    () => debounce(setState, waitAmount),
    [waitAmount],
  );

  /*
  NOTE: passing a reset here to enable functionality for resetting the state
  and bypassing the debounce. An example use case for this reset is for the
  clearing of an input, which does not need to "wait" for the user to stop typing.
  */
  const reset = useCallback(() => {
    setState(initialState);
  }, [initialState]);

  return [state, setStateDebounced, reset];
};
