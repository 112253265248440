import { generatePath } from 'react-router-dom';
import { curry, pick } from 'ramda';

import type { RouteParams } from '~/types';
import { RoutePaths } from '~/enums';

export const getRouteUrlFromPath = curry(
  (routePath: RoutePaths, routeParams: RouteParams) =>
    generatePath(routePath, routeParams),
);

export const pickRouteParams = pick([
  'jobId',
  'jobItemId',
  'jobItemStep',
  'contentItemId',
  'contentItemType',
  'contentItemTypeId',
  'adminTab',
  'policy',
]);

export const mergeRouteParams = (...paramsList: RouteParams[]) =>
  Object.assign({}, ...paramsList.map(pickRouteParams));

export const getIsAbsoluteUrl = (url?: string) =>
  Boolean(
    url?.startsWith('http') ||
      url?.startsWith('//') ||
      url?.startsWith('mailto:'),
  );

export const getIsServerUrl = (url?: string) =>
  Boolean(url?.startsWith('/server'));
