import { FC, createContext, useContext, useMemo, useEffect } from 'react';

import { SetStateFp, useStateFp } from '~/hooks';

import type { MatterportSDK, ThreeObj3D } from '~/types';
import { findModelById } from '~/utils';

export type MatterportJobItemContextProps = {
  setSDK: SetStateFp<MatterportSDK>;
  setSelectedModelId: SetStateFp<string | undefined>;
  SDK?: MatterportSDK;
  selectedModelId?: string;
  selectedModel?: ThreeObj3D | undefined;
};

const defaultContextValue: MatterportJobItemContextProps = {
  setSDK: () => null,
  setSelectedModelId: () => undefined,
};

const MatterportJobItemContext = createContext(defaultContextValue);

export const MatterportJobItemProvider: FC = ({ children }) => {
  const [SDK, setSDK] = useStateFp<MatterportSDK>(null);
  const [selectedModelId, setSelectedModelId] = useStateFp<string | undefined>(
    undefined,
  );
  const [selectedModel, setSelectedModel] = useStateFp<ThreeObj3D | undefined>(
    undefined,
  );

  useEffect(() => {
    if (selectedModelId) {
      findModelById(SDK, selectedModelId).then(_model => {
        if (_model) {
          setSelectedModel(_model);
        }
      });
    } else {
      setSelectedModel(undefined);
    }
  }, [SDK, selectedModelId, setSelectedModel]);

  const contextValue = useMemo(
    () => ({
      SDK,
      setSDK,
      selectedModelId,
      setSelectedModelId,
      selectedModel,
    }),
    [SDK, setSDK, selectedModelId, setSelectedModelId, selectedModel],
  );

  return (
    <MatterportJobItemContext.Provider value={contextValue}>
      {children}
    </MatterportJobItemContext.Provider>
  );
};

export const useMatterportJobItemContext = () =>
  useContext(MatterportJobItemContext);
