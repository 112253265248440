import { combineReducers } from '@reduxjs/toolkit';

import {
  reducer as todosReducer,
  SLICE_NAME as TODOS_SLICE_NAME,
} from './todosExample';

const rootReducer = combineReducers({
  [TODOS_SLICE_NAME]: todosReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
