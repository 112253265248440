import { DEFAULT_THUMBNAIL_URL, SERVER_URL } from "../constants";
import { ContentItem } from "../types";

export const getThumbnailUrlForContentItem = (contentItem: ContentItem) => {

    if (!contentItem.thumbnailFile) return DEFAULT_THUMBNAIL_URL;
    const filename = contentItem.thumbnailFile.azureFilename

    /*
    NOTE: attached the filename to the url so that
    new thumbnails have unique urls and prevents
    caching of outdated images
    */
    return `${SERVER_URL}/content-item/thumbnail/${contentItem.id}/${filename}`
}
