import { gql } from '@kv/apollo-client';

export const ContentItemTypeFragment = gql`
  fragment ContentItemTypeFragment on ContentItemType {
    __typename
    id
    value
  }
`;

export const GetContentItemTypes = gql`
  query GetContentItemTypes($filters: ContentItemTypeFilter) {
    contentItemTypes(filters: $filters) {
      ...ContentItemTypeFragment
    }
  }
  ${ContentItemTypeFragment}
`;

export const GetContentItemType = gql`
  query GetContentItemType($id: ID!) {
    contentItemType(id: $id) {
      ...ContentItemTypeFragment
    }
  }
  ${ContentItemTypeFragment}
`;
