import { gql } from '@kv/apollo-client';

import { VisibilityTypeFragment } from './visibilityTypes';
import { ContentItemFragment } from './contentItems';

export const JobItemFragment = gql`
  fragment JobItemFragment on JobItem {
    __typename
    id
    expirationDate
    jobItemUrl
    visibilityType {
      ...VisibilityTypeFragment
    }
    contentItem {
      ...ContentItemFragment
    }
    viewerUsers {
      id
      name
    }
    viewerUserGroups {
      id
      value
    }
    matterportSceneData
  }
  ${VisibilityTypeFragment}
  ${ContentItemFragment}
`;

export const GetJobItems = gql`
  query GetJobItems {
    jobItems {
      ...JobItemFragment
    }
  }
  ${JobItemFragment}
`;

export const GetJobItem = gql`
  query GetJobItem($id: ID!) {
    jobItem(id: $id) {
      ...JobItemFragment
    }
  }
  ${JobItemFragment}
`;

export const CreateJobItem = gql`
  mutation CreateJobItem($input: JobItemInput!) {
    createJobItem(input: $input) {
      ...JobItemFragment
    }
  }
  ${JobItemFragment}
`;

export const UpdateJobItem = gql`
  mutation UpdateJobItem($id: ID!, $input: JobItemInput!) {
    updateJobItem(id: $id, input: $input) {
      ...JobItemFragment
    }
  }
  ${JobItemFragment}
`;

export const DeleteJobItems = gql`
  mutation DeleteJobItems($ids: [ID]) {
    deleteJobItems(ids: $ids) {
      ids
    }
  }
`;
