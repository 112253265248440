import { FC } from 'react';
import { Redirect } from 'react-router-dom';

import { RoutePaths } from '~/enums';
import { useCurrentUserContext } from '~/context';

import AsyncContent from '~/components/AsyncContent';

const AdminContent: FC = ({ children }) => {
  const { loading, error, isAdminUser } = useCurrentUserContext();

  return (
    <AsyncContent loading={loading} error={error}>
      {!isAdminUser ? <Redirect to={RoutePaths.JOBS_LIST} /> : <>{children}</>}
    </AsyncContent>
  );
};

export default AdminContent;
