import { useEffect, useCallback } from 'react';

// NOTE: https://medium.com/@nicolaslopezj/reusing-logic-with-react-hooks-8e691f7352fa
export const useKeyboardEvent = (key: string, callback: () => unknown) => {
  const handler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === key) {
        callback();
      }
    },
    [key, callback],
  );

  useEffect(() => {
    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };

    // this dependency array must be present AND empty - ignoring the warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
