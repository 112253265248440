import { FC, createContext, useContext, useMemo, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import type { QueryResult } from '@kv/apollo-client';

import type { AuthTokensQueryResult } from '~/types';

//* We use this context in authenticated query/mutation hooks so we import
//* this query directly from its file to avoid circular dependencies
import { useGetAuthTokensQuery } from '~/hooks/graphql/auth';

import { getIsValidToken, getTokenFromLocalStorage, getTokenFromURLSearchParams, saveTokenIntoLocalStorage } from '~/utils';

export type AuthTokensContextProps = {
  loading: QueryResult['loading'];
  error?: QueryResult['error'];
  authTokens?: AuthTokensQueryResult['authTokens'];
  isAuthenticated: boolean;
  refetch: () => void;
};

const defaultContextValue: AuthTokensContextProps = {
  loading: false,
  error: undefined,
  authTokens: undefined,
  isAuthenticated: false,
  refetch: () => undefined,
};

const AuthTokensContext = createContext(defaultContextValue);

export const AuthTokensProvider: FC = ({ children }) => {
  const history = useHistory();

  const { data, loading, error, refetch } = useGetAuthTokensQuery();

  const getAccessToken = useCallback(() => {

    // always check the localstorage for the token
    const _tokenLocalStorage = getTokenFromLocalStorage();
    const _tokenLocalStorageIsValid = (_tokenLocalStorage && _tokenLocalStorage !== "null");
    if (_tokenLocalStorageIsValid) return _tokenLocalStorage;

    // check the search params for the token
    const _searchParamsToken = getTokenFromURLSearchParams();
    if (_searchParamsToken) {
      saveTokenIntoLocalStorage(_searchParamsToken);
      return _searchParamsToken;
    }

    // try and get it from the data
    if (!loading && data?.authTokens) {
      const _token = data?.authTokens.accessToken
      saveTokenIntoLocalStorage(_token);
      return _token;
    }
  }, [loading, data])

  const accessToken = useMemo(() => getAccessToken(), [getAccessToken]);

  const refetchAuthToken = useCallback(() => {
    const _token = getAccessToken();
    if (!_token) refetch();
  }, [getAccessToken, refetch]);

  useEffect(() => history.listen(() => refetchAuthToken()), [history, refetchAuthToken])

  const contextValue = useMemo(() => ({
    loading,
    error,
    refetch,
    isAuthenticated: getIsValidToken(accessToken),
  }), [error, loading, refetch, accessToken]);

  return (
    <AuthTokensContext.Provider value={contextValue}>
      {children}
    </AuthTokensContext.Provider>
  );
};

export const useAuthTokensContext = () => useContext(AuthTokensContext);
