import { VisibilityTypeValues } from '~/enums';
import type { SelectOption } from '~/types';

export const getHumanReadableVisibilityType = (
  visibilityTypeValue?: VisibilityTypeValues,
): string => {
  switch (visibilityTypeValue) {
    case VisibilityTypeValues.PUBLIC: {
      return 'Public';
    }
    case VisibilityTypeValues.BD_ONLY: {
      return 'BD Only';
    }
    case VisibilityTypeValues.BY_BUSINESS_UNIT: {
      return 'By Business Unit';
    }
    case VisibilityTypeValues.BY_USERS_GROUPS: {
      return 'By Users/Groups';
    }
    case VisibilityTypeValues.DISABLED: {
      return 'Disabled';
    }
    default: {
      return 'Unknown';
    }
  }
};

export const renderVisibilityTypeSelectOption = (option: SelectOption) =>
  getHumanReadableVisibilityType(option.value as VisibilityTypeValues);
