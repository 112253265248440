import { FC } from 'react';
import { ApolloProvider } from '@kv/apollo-client';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import { BrowserRouter } from 'react-router-dom';
import { OverlayProvider } from '@react-aria/overlays';

import { apolloClient } from '~/apollo';
import store from '~/state';
import { AuthTokensProvider, CurrentUserProvider } from '~/context';

import ErrorBoundary from '~/components/ErrorBoundary';

const AppWrapper: FC = ({ children }) => (
  <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <ToastProvider autoDismiss autoDismissTimeout={5000}>
        <OverlayProvider>
          <BrowserRouter>
            <AuthTokensProvider>
              <CurrentUserProvider>
                <ErrorBoundary>{children}</ErrorBoundary>
              </CurrentUserProvider>
            </AuthTokensProvider>
          </BrowserRouter>
        </OverlayProvider>
      </ToastProvider>
    </Provider>
  </ApolloProvider>
);

export default AppWrapper;
