import { useMemo, useCallback } from 'react';
import { Redirect, useParams, useHistory } from 'react-router-dom';

import { RoutePaths } from '~/enums';
import { getRouteUrlFromPath, mergeRouteParams } from '~/utils';
import type { RouteParams } from '~/types';

const useGetRouteUrl = () => {
  const params = useParams<RouteParams>();

  const getRouteUrl = useCallback(
    (routePath: RoutePaths, extraParams: RouteParams = {}) =>
      getRouteUrlFromPath(routePath, mergeRouteParams(params, extraParams)),
    [params],
  );

  return getRouteUrl;
};

export const useGetAppRouteUrl = (
  routePath: RoutePaths,
  extraParams?: RouteParams,
) => {
  const getRouteUrl = useGetRouteUrl();
  const routeUrl = useMemo(
    () => getRouteUrl(routePath, extraParams),
    [getRouteUrl, routePath, extraParams],
  );

  return routeUrl;
};

export const useGoToAppRoute = (
  routePath: RoutePaths,
  defaultParams: RouteParams = {},
) => {
  const routerHistory = useHistory();
  const getRouteUrl = useGetRouteUrl();

  const goToRoute = useCallback(
    (extraParams: RouteParams = {}) => {
      const routeUrl = getRouteUrl(
        routePath,
        mergeRouteParams(extraParams, defaultParams),
      );

      routerHistory.push(routeUrl);
    },
    [routerHistory, getRouteUrl, routePath, defaultParams],
  );

  return goToRoute;
};

type RedirectToRouteProps = {
  params?: RouteParams;
};

export const useRedirectToAppRoute = (
  routePath: RoutePaths,
  defaultParams: RouteParams = {},
) => {
  const getRouteUrl = useGetRouteUrl();

  const RedirectToRoute = useCallback(
    ({ params = {} }: RedirectToRouteProps = {}) => {
      const routeUrl = getRouteUrl(
        routePath,
        mergeRouteParams(defaultParams, params),
      );

      return <Redirect to={routeUrl} />;
    },
    [getRouteUrl, routePath, defaultParams],
  );

  return RedirectToRoute;
};
