import { useMemo } from 'react';

import {
  GetUserRoles,
  GetUserRole,
  CreateUserRole,
  UpdateUserRole,
  DeleteUserRoles,
} from '~/graphql';

import type {
  UserRolesQueryResultData,
  UserRoleQueryResultData,
  CreateUpdateUserRoleInput,
  CreateUpdateUserRoleMutationResultData,
  DeleteUserRolesMutationResultData,
} from '~/types';

import {
  makeGetEntitiesQueryHook,
  makeGetEntityByIdQueryHook,
  makeCreateEntityMutationHook,
  makeUpdateEntityMutationHook,
  makeDeleteEntitiesMutationHook,
  getIlikeFilterValue,
  getInFilterValue,
} from './utils';

export const useGetUserRolesQuery =
  makeGetEntitiesQueryHook<UserRolesQueryResultData>(GetUserRoles);

export const useGetUserRoleByIdQuery =
  makeGetEntityByIdQueryHook<UserRoleQueryResultData>(GetUserRole);

export const useCreateUserRoleMutation = makeCreateEntityMutationHook<
  CreateUpdateUserRoleMutationResultData,
  CreateUpdateUserRoleInput
>(CreateUserRole);

export const useUpdateUserRoleMutation = makeUpdateEntityMutationHook<
  CreateUpdateUserRoleMutationResultData,
  CreateUpdateUserRoleInput
>(UpdateUserRole);

export const useDeleteUserRolesMutation =
  makeDeleteEntitiesMutationHook<DeleteUserRolesMutationResultData>(
    DeleteUserRoles,
  );

type SearchRoleQueryVars = {
  value?: string;
  ids?: string[];
};

export const useSearchUserRolesQuery = ({
  value,
  ids,
}: SearchRoleQueryVars) => {
  const variables = useMemo(
    () => ({
      filters: {
        valueIlike: getIlikeFilterValue(value),
        idIn: getInFilterValue(ids),
      },
    }),
    [value, ids],
  );

  return useGetUserRolesQuery(variables);
};
