import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { RoutePaths } from '~/enums';

import {
  getRedirectPathFromLocalStorage,
  isValidRedirectPath,
  saveRedirectPathIntoLocalStorage,
  removeTokenFromLocalStorage,
} from '../../utils';

import LoadingIndicator from '../LoadingIndicator';

const NotAuthenticatedInfo = () => {
  const router = useHistory();
  const location = useLocation();

  // redirect path from search params - eg: areaxt.com?redirectUrl=...
  const searchRedirectPath = useMemo(() => {
    if (location.search === '') return;
    const parsedSearchParams = new URLSearchParams(location.search);
    return parsedSearchParams.get('redirectUrl');
  }, [location]);
  const searchRedirectPathIsValid = isValidRedirectPath(searchRedirectPath);

  // redirect path from current path - eg: areaxt.com/content
  const currentRedirectPath = location.pathname;
  const currentRedirectPathIsValid = isValidRedirectPath(currentRedirectPath);

  // redirect path from local storage
  const previousRedirectPath = getRedirectPathFromLocalStorage();
  const previousRedirectPathIsValid = isValidRedirectPath(previousRedirectPath);

  const redirectPathToSave = useMemo(() => {
    if (searchRedirectPathIsValid) return searchRedirectPath || '';
    if (currentRedirectPathIsValid) return currentRedirectPath || '';
    if (previousRedirectPathIsValid) return previousRedirectPath || '';
    return '';
  }, [
    currentRedirectPathIsValid,
    previousRedirectPathIsValid,
    currentRedirectPath,
    previousRedirectPath,
    searchRedirectPath,
    searchRedirectPathIsValid,
  ]);

  const handleSaveRedirectPath = useCallback(() => {
    saveRedirectPathIntoLocalStorage(redirectPathToSave);
  }, [redirectPathToSave]);

  const handleGoToLoginPage = useCallback(() => {
    removeTokenFromLocalStorage();
    handleSaveRedirectPath();
    router.push(RoutePaths.LOGIN);
  }, [handleSaveRedirectPath, router]);

  useEffect(() => {
    const _t = 250;
    setTimeout(handleGoToLoginPage, _t);
  }, [handleGoToLoginPage]);

  return <LoadingIndicator />;
};

export default NotAuthenticatedInfo;
